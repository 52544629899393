import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import { SiteContext } from "../../context";
import { COPY_TEXT, PAYMENT_MODE } from "../../constant";
import InputBase from "@mui/material/InputBase";
import QRImg from "../../assets/QR_upi.png";
import {
  fetcTransactionDetailsByMode,
  fetchTransactionStatus,
  submitCheckoutDetails,
} from "../../utils/service";
import { useNavigate } from "react-router-dom";
import Note from "../Note";
import ToastMessage from "../Toast";
import Phonepe from "../../assets/phonepe1.png";
import Gpay from "../../assets/gpay1.png";
import Paytm from "../../assets/paytm1.png";
import UPI from "../../assets/upi1.png";
import Vector from "../../assets/Vector.png";
import Phonepe1 from "../Logo/PhonePe";
import Gpay1 from "../Logo/GPay";
import Paytm1 from "../Logo/Paytm";
import UPI1 from "../Logo/UPI";

export default function PaymentUpi({ setTimerValue, setShowTimer }) {
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState();

  const { transactionDetail, setTransactionDetail } = useContext(SiteContext);
  const [loading, setLoading] = useState(true);
  const [upiDetail, setUpiDetail] = useState(transactionDetail);
  const [utrNumber, setUtrNumber] = useState("");
  const [indicatorText, setIndicatorText] = useState(COPY_TEXT);
  const [link, setLink] = useState("");
  const [link1, setLink1] = useState("");
  const [showVideo, setShowVideo] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [toastMsg, setToastMsg] = useState();

  const [error, setError] = useState(false);
  const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    const checkIsDesktop = () => {
      setIsDesktop(window.innerWidth >= 1024); // Adjust the threshold based on your needs
    };

    // Initial check
    checkIsDesktop();

    // Add event listener to check window size on resize
    window.addEventListener("resize", checkIsDesktop);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkIsDesktop);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []); // Empty dependency array ensures the effect runs only once (on mount)

  const remainingSeconds = seconds;

  useEffect(() => {
    localStorage.setItem("remainingSeconds", seconds);
  }, [seconds]);

  useEffect(() => {
    if (upiDetail) {
      if (upiDetail?.intentUri) {
      } else if (!upiDetail?.isUtrCollect) {
        const intentSeconds =
          !isDesktop && upiDetail?.mobileIntent
            ? upiDetail?.mobileIntent
            : isDesktop && upiDetail?.desktopIntent
            ? upiDetail.desktopIntent
            : upiDetail?.qrcode
            ? 90
            : window.innerWidth >= 1024
            ? 30
            : 60;
        fetchContinuousStatus();
        startCounter(intentSeconds);
        setSeconds(intentSeconds);
      } else {
        const collectSeconds =
          !isDesktop && upiDetail?.mobileCollect
            ? upiDetail?.mobileCollect
            : isDesktop && upiDetail?.desktopCollect
            ? upiDetail.desktopCollect
            : 150;
        fetchContinuousStatus();
        setSeconds(collectSeconds);
        startCounter(collectSeconds);
      }
    }
  }, [upiDetail, isDesktop]);

  useEffect(() => {
    getUpiDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUpiDetail = async () => {
    setLoading(true);
    try {
      const result = await fetcTransactionDetailsByMode(
        PAYMENT_MODE?.upi,
        transactionDetail?.paymentId,
        transactionDetail?.transactionId
      );
      if (result?.status === "success") {
        setLoading(false);
        setUpiDetail(result?.data);
        setLink(
          `https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=upi%3A%2F%2Fpay%3Fpn%3D${result?.data?.accountHolderName}%26pa%3D${result?.data?.upiId}%26am=${result?.data?.amount}`
        );
        setLink1(
          `https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=upi%3A%2F%2Fpay%3Fpn%3D${result?.data?.accountHolderName}%26pa%3D${result?.data?.upiId}%26am=${result?.data?.amount}`
        );
      } else {
        if (result?.response?.data?.status === "error") {
          return navigate("/failed", {
            state: {
              errorMsg:
                result?.response?.data?.message || "Something went wrong",
              status: result?.response?.data?.status,
            },
          });
        }
        console.log("link expired");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const submitHandler = async (defaultTimer) => {
    if (disableSubmit) return;
    setDisableSubmit(true);
    const data = {
      paymentId: upiDetail?.paymentId,
      utrNumber: utrNumber,
      transactionId: upiDetail?.transactionId,
      merchantId: upiDetail?.merchantId,
      payMode: PAYMENT_MODE?.upi,
    };
    try {
      const result = await submitCheckoutDetails(data);
      if (result) {
        // setShowModal(true);
        setShowTimer(true);
        fetchContinuousStatus();
        startCounter(defaultTimer ? defaultTimer : 30);
        // window.location.href = `${transactionDetail?.callBackUrl}?transaction_id=${transactionDetail?.transactionId}`;
      } else {
        setDisableSubmit(false);
      }
    } catch (error) {
      setDisableSubmit(false);
      setError(error?.response?.data?.message || "Something went wrong");
      setToastMsg(error?.response?.data?.message || "Something went wrong");

      // return navigate("/failed", {
      //   state: {
      //     errorMsg: error?.response?.data?.message || "Something went wrong",
      //   },
      // });
    }
  };
  const startCounter = (timerStart = 30) => {
    setTimerValue(timerStart);
    clearInterval(window.timerInterval);
    window.timerInterval = setInterval(() => {
      timerStart--;
      if (timerStart < 0) {
        const sign = transactionDetail?.callBackUrl?.includes("?") ? "&" : "?";
        window.location.href = `${transactionDetail?.callBackUrl}${sign}transaction_id=${transactionDetail?.transactionId}`;
      } else {
        setTimerValue(timerStart);
      }
    }, 1000);
  };
  const fetchContinuousStatus = () => {
    setInterval(
      async () => {
        const result = await fetchTransactionStatus(
          transactionDetail?.paymentId,
          transactionDetail?.transactionId
        );
        if (result === "SUCCESS" || result === "FAILED") {
          const sign = transactionDetail?.callBackUrl?.includes("?")
            ? "&"
            : "?";
          window.location.href = `${transactionDetail?.callBackUrl}${sign}transaction_id=${transactionDetail?.transactionId}`;
        }
      },
      upiDetail?.isUtrCollect ? 10000 : 10000
    );
  };

  const InputBaseStyle = {
    "label + &": {
      // marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      marginBottom: "8px",
      borderRadius: 4,
      position: "relative",
      // backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
      border: "1px solid",
      // borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
      fontSize: 16,
      width: "auto",
      padding: "10px 12px",
      // transition: theme.transitions.create([
      //   "border-color",
      //   "background-color",
      //   "box-shadow",
      // ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        // borderColor: theme.palette.primary.main,
      },
    },
  };

  const handleClose = () => {
    setToastMsg();
  };

  const openApp = (app) => {
    if (upiDetail?.isUtrCollect || isDesktop) {
      return;
    } else {
      // submitHandler(seconds);
      setShowTimer(true);
      fetchContinuousStatus();
      startCounter(seconds ? seconds : 30);
      if (app === "gpay") {
        window.location.href = upiDetail.gpayLink;
      } else if (app === "paytm") {
        window.location.href = upiDetail.paytmLink;
      } else {
        window.location.href = upiDetail.phonePeLink;
      }
    }
  };

  if (upiDetail?.intentUri) {
    return (
      <iframe
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          top: 0,
          left: 0,
          background: "white",
        }}
        src={upiDetail?.intentUri}
        allow="clipboard-read; clipboard-write"
      />
    );
  }

  if (loading) {
    return (
      <CircularProgress
        style={{ position: "absolute", top: "45%", left: "45%" }}
      />
    );
  }

  return (
    <>
      <ToastMessage
        open={toastMsg}
        message={toastMsg}
        handleClose={handleClose}
      />

      <Grid
        xs={12}
        sx={{
          border: "1px solid #E3E8EF",
          borderRadius: "8px",
          textAlign: "center",
          padding: "16px",
          marginBottom: "24px",
          marginLeft: 2,
        }}
      >
        <Grid
          style={{
            textAlign: "center",
            fontSize: 14,
            color: "red",
            fontWeight: "bold",
            textDecoration: "underline",
            marginBottom: 20,
            marginTop: 10,
          }}
          display={{ xs: "none", md: "block" }}
        >
          {remainingSeconds > 0 && (
            <div>{remainingSeconds} SECONDS REMAINING</div>
          )}
        </Grid>
        <div style={{ marginBottom: "16px" }} className="MuiCardHeader-title ">
          Open any UPI app and scan this QR Code
        </div>
        <Grid
          display={{ xs: "block", md: "none" }}
          xs={{
            padding: "16px",
            borderRadius: "8px",
            border: "1px solid #E6EAEF",
          }}
        >
          {upiDetail ? (
            upiDetail?.intentUri ? (
              <iframe
                src={upiDetail?.intentUri}
                allow="clipboard-read; clipboard-write"
              />
            ) : upiDetail?.upiLink ? (
              <img
                src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(
                  upiDetail?.upiLink
                )}`}
                alt="payment"
                onLoad={() => setLoading(false)}
                style={{ display: loading ? "none" : "initial" }}
              />
            ) : upiDetail?.bankImage ? (
              <img
                src={upiDetail?.bankImage}
                alt="upi-qr"
                height={150}
                width={150}
                onLoad={() => setLoading(false)}
                style={{ display: loading ? "none" : "initial" }}
              />
            ) : upiDetail?.intentQRLink ? (
              <img
                src={upiDetail?.intentQRLink}
                alt="upi-qr"
                height={200}
                width={200}
                onLoad={() => setLoading(false)}
                style={{ display: loading ? "none" : "initial" }}
              />
            ) : (
              <img src={link} alt="upi-qr" />
            )
          ) : (
            <></>
          )}

          {/* <div display="flex" justifyContent="center">s</div> */}
          {/* <img src={link} alt="upi-qr"></img> */}
        </Grid>
        <Grid
          display={{ xs: "none", md: "block" }}
          xs={{
            padding: "16px",
            borderRadius: "8px",
            border: "1px solid #E6EAEF",
          }}
        >
          {upiDetail ? (
            upiDetail?.intentUri ? (
              <iframe
                src={upiDetail?.intentUri}
                allow="clipboard-read; clipboard-write"
              />
            ) : upiDetail?.upiLink ? (
              <img
                src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(
                  upiDetail?.upiLink
                )}`}
                alt="upi-qr"
                onLoad={() => setLoading(false)}
                style={{ display: loading ? "none" : "initial" }}
              />
            ) : upiDetail?.bankImage ? (
              <img
                src={upiDetail?.bankImage}
                alt="upi-qr"
                height={150}
                width={150}
                onLoad={() => setLoading(false)}
                style={{ display: loading ? "none" : "initial" }}
              />
            ) : upiDetail?.intentQRLink ? (
              <img
                src={upiDetail?.intentQRLink}
                alt="upi-qr"
                height={200}
                width={200}
                onLoad={() => setLoading(false)}
                style={{ display: loading ? "none" : "initial" }}
              />
            ) : (
              <img src={link1} alt="upi-qr" />
            )
          ) : (
            <></>
          )}

          {/* <div display="flex" justifyContent="center">s</div> */}
          {/* <img src={link1} alt="upi-qr"></img> */}
        </Grid>
        {!upiDetail?.intentQRLink ? (
          <div
            style={{
              fontSize: 14,
              textAlign: "center",
              color: "blue",
              fontWeight: "bold",
              margin: "20px 0px",
            }}
          >
            OR
          </div>
        ) : (
          <></>
        )}
        {upiDetail?.isUtrCollect ? (
          <>
            <div
              style={{
                textAlign: "center",
                fontSize: 14,
                fontWeight: 500,
                marginBottom: 20,
              }}
            >
              Copy the UPI ID below to pay
            </div>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 2,
                background: "rgba(11, 100, 242, 0.05)",
                borderRadius: "12px",
                border: "1px solid #E3E8EF",
              }}
            >
              <div style={{ overflowWrap: "anywhere" }}>{upiDetail?.upiId}</div>
              <Box
                sx={{
                  color: "#0B64F2",
                  cursor: "pointer",
                  fontWeight: 500,
                  fontSize: 13,
                }}
                onClick={() => {
                  setToastMsg("Copied");
                  navigator.clipboard.writeText(upiDetail?.upiId);
                }}
              >
                Copy
              </Box>
            </Box>
          </>
        ) : (
          <div style={{ textAlign: "center", fontSize: 14, fontWeight: 500 }}>
            Pay using below UPI Apps
          </div>
        )}

        {upiDetail?.isUtrCollect || upiDetail?.intentQRLink ? (
          <></>
        ) : (
          <>
            <Grid
              display={{ xs: "block", md: "none" }}
              container
              sx={{ mt: 3 }}
            >
              {upiDetail?.upiLink ? (
                // <img
                //   style={{ marginLeft: 10 }}
                //   onClick={() =>
                //     (window.location.href = upiDetail?.upiLink)
                //   }
                //   height={50}
                //   src={more}
                //   alt="more"
                // />
                <Grid
                  onClick={() => {
                    if (upiDetail?.isUtrCollect || isDesktop) {
                      return;
                    } else {
                      setShowTimer(true);
                      fetchContinuousStatus();
                      startCounter(seconds ? seconds : 30);
                      window.location.href = upiDetail?.upiLink;
                    }
                  }}
                  col
                  sm={12}
                  md={12}
                  xs={12}
                  sx={{
                    borderRadius: 2,
                    border: "2px solid #E3E8EF",
                    p: "10px 15px",
                    mb: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <img height={40} src={UPI} />
                    <div
                      style={{
                        marginLeft: 25,
                      }}
                    >
                      Pay with UPI App
                    </div>
                  </div>

                  <img height={15} src={Vector} />
                </Grid>
              ) : (
                <></>
              )}
              {upiDetail?.phonePeLink ? (
                // <img
                //   onClick={() => openApp("phonepe")}
                //   style={{ marginLeft: 10, marginRight: 10 }}
                //   height={40}
                //   src={phonePe}
                //   alt="phonepe"
                // />
                <Grid
                  onClick={() => openApp("phonepe")}
                  col
                  sx={{
                    borderRadius: 2,
                    border: "2px solid #E3E8EF",
                    p: "10px 15px",
                    mb: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <img height={40} src={Phonepe} />
                    <div
                      style={{
                        marginLeft: 25,
                      }}
                    >
                      Pay with PhonePe
                    </div>
                  </div>

                  <img height={15} src={Vector} />
                </Grid>
              ) : (
                <></>
              )}
              {upiDetail?.gpayLink ? (
                // <img
                //   onClick={() => openApp("gpay")}
                //   height={50}
                //   src={gpay}
                //   alt="gpay"
                // />
                <Grid
                  onClick={() => openApp("gpay")}
                  col
                  sm={12}
                  xs={12}
                  md={12}
                  sx={{
                    borderRadius: 2,
                    border: "2px solid #E3E8EF",
                    mb: 2,
                    display: "flex",
                    p: "10px 15px",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <img height={40} src={Gpay} />
                    <div
                      style={{
                        marginLeft: 25,
                      }}
                    >
                      Pay with Gpay
                    </div>
                  </div>

                  <img height={15} src={Vector} />
                </Grid>
              ) : (
                <></>
              )}
              {upiDetail?.paytmLink ? (
                // <img
                //   onClick={() => openApp("paytm")}
                //   height={70}
                //   src={paymt}
                //   alt="paytm"
                // />
                <Grid
                  onClick={() => openApp("paytm")}
                  col
                  sm={12}
                  xs={12}
                  md={12}
                  sx={{
                    borderRadius: 2,
                    border: "2px solid #E3E8EF",
                    p: "10px 15px",
                    mb: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <img height={40} src={Paytm} />
                    <div
                      style={{
                        marginLeft: 25,
                      }}
                    >
                      Pay with Paytm
                    </div>
                  </div>
                  <img height={15} src={Vector} />
                </Grid>
              ) : (
                <></>
              )}
            </Grid>

            <Grid
              style={{
                marginTop: 30,
                marginBottom: 20,
                cursor: "pointer",
              }}
              display={{ xs: "none", md: "block" }}
              className="flex-row flex-sm-column"
            >
              {upiDetail?.upiLink ? (
                // <img
                //   style={{ marginLeft: 10 }}
                //   onClick={() =>
                //     (window.location.href = upiDetail?.upiLink)
                //   }
                //   height={50}
                //   src={more}
                //   alt="more"
                // />
                <UPI1
                  className="mb-sm-2 mb-2 mr-sm-0 mr-4"
                  style={{
                    borderRadius: 2,
                    border: "2px solid #E3E8EF",
                    padding: "10px",
                    marginRight: 10,
                  }}
                  // onClick={() => (window.location.href = upiDetail?.upiLink)}
                />
              ) : (
                <></>
              )}
              {upiDetail?.phonePeLink ? (
                // <img
                //   onClick={() => openApp("phonepe")}
                //   style={{ marginLeft: 10, marginRight: 10 }}
                //   height={40}
                //   src={phonePe}
                //   alt="phonepe"
                // />
                <Phonepe1
                  className="mb-2 mr-4"
                  style={{
                    borderRadius: 2,
                    border: "2px solid #E3E8EF",
                    padding: "10px",
                    marginRight: 10,
                  }}
                  // onClick={() => openApp("phonepe")}
                />
              ) : (
                <></>
              )}
              {upiDetail?.gpayLink ? (
                // <img
                //   onClick={() => openApp("gpay")}
                //   height={50}
                //   src={gpay}
                //   alt="gpay"
                // />
                <Gpay1
                  className="mb-xs-2 mb-2"
                  style={{
                    borderRadius: 2,
                    border: "2px solid #E3E8EF",
                    padding: "10px",
                    marginRight: 10,
                  }}
                  // onClick={() => openApp("gpay")}
                />
              ) : (
                <></>
              )}
              {upiDetail?.paytmLink ? (
                // <img
                //   onClick={() => openApp("paytm")}
                //   height={70}
                //   src={paymt}
                //   alt="paytm"
                // />
                <Paytm1
                  className="mb-sm-2 mb-2 mr-sm-0 mr-4"
                  style={{
                    borderRadius: 2,
                    border: "2px solid #E3E8EF",
                    padding: "10px",
                    marginRight: 10,
                  }}
                  // onClick={() => openApp("paytm")}
                />
              ) : (
                <></>
              )}
            </Grid>
          </>
        )}
      </Grid>

      <Box
        component="form"
        noValidate
        sx={{
          display: "grid",
          gridTemplateColumns: { sm: "1fr" },
          gap: 1,
          margin: "0px 16px",
        }}
      >
        {upiDetail?.isUtrCollect ? (
          <FormControl variant="standard">
            <InputLabel shrink htmlFor="upi-utr-no-input">
              Bank ref. no.
            </InputLabel>
            <InputBase
              placeholder="Enter 12 digit UTR no."
              id="upi-utr-no-input"
              value={utrNumber}
              sx={{ ...InputBaseStyle }}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (
                  (re.test(e.target.value) || e.target.value === "") &&
                  e.target.value.length <= 12
                ) {
                  setUtrNumber(e.target.value);
                }
              }}
            />
          </FormControl>
        ) : (
          <></>
        )}

        {upiDetail?.isUtrCollect ? (
          <FormControl>
            <Button
              sx={{
                borderRadius: "50px",
                padding: "12px 30px",
                fontSize: "1rem",
                marginTop: "16px",
              }}
              variant="contained"
              size="large"
              disabled={disableSubmit || utrNumber.length !== 12}
              onClick={(e) => {
                e.preventDefault();
                submitHandler();
              }}
            >
              Continue
            </Button>
          </FormControl>
        ) : (
          <></>
        )}
        <Box display={{ xs: "block", lg: "none" }}>
          <Note />
        </Box>
      </Box>
    </>
  );
}
